// extracted by mini-css-extract-plugin
export var button = "_404-module--button--3230e";
export var container = "_404-module--container--b557a";
export var contentContainer = "_404-module--contentContainer--704c1";
export var leftElementContainer = "_404-module--leftElementContainer--05f0d";
export var outlineTitle = "_404-module--outlineTitle--3480b";
export var page = "_404-module--page--7e291";
export var rightElementContainer = "_404-module--rightElementContainer--3c8c3";
export var subtitle = "_404-module--subtitle--da9eb";
export var subtitleContainer = "_404-module--subtitleContainer--8479c";
export var title = "_404-module--title--99db8";
export var titleContainer = "_404-module--titleContainer--f29cf";