import { graphql } from 'gatsby';
import React from 'react';
import PageSEO from '../components/PageSEO';
import ButtonLink from '../components/ui/ButtonLink';
import Layout from '../components/ui/Layout';
import leftElement from '../images/frameElement-DarkGreen.png';
import rightElement from '../images/frameElement-LightGreen.png';

import * as styles from './404.module.scss';

export const query = graphql`
  {
    sanityLpPersonalityQuiz {
      bookACallButtonLink
      logo {
        asset {
          url
        }
      }
      logoIcon {
        asset {
          url
        }
      }
    }
  }
`;

interface QueryData {
  data: {
    sanityLpPersonalityQuiz: {
      bookACallButtonLink: string;
      logo: {
        asset: {
          url: string;
        };
      };
      logoIcon: {
        asset: {
          url: string;
        };
      };
    };
  };
}

const Page404 = ({ data }: QueryData): React.ReactElement => {
  const { bookACallButtonLink } = data.sanityLpPersonalityQuiz;
  return (
    <Layout
      headerBackgroundColor={'white'}
      headerBookACallLink={bookACallButtonLink}
      logoUrl={data.sanityLpPersonalityQuiz.logo.asset.url}
      logoIconUrl={data.sanityLpPersonalityQuiz.logoIcon.asset.url}
    >
      <PageSEO
        pageSEO={{
          title: 'Page not found',
          description: 'The address entered do not match any page on our website.',
        }}
      />
      <div className={styles.page}>
        <div className={styles.rightElementContainer}>
          <img src={rightElement} alt="" />
        </div>
        <div className={styles.leftElementContainer}>
          <img src={leftElement} alt="" />
        </div>
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <h1 className={styles.title}>
              404
              <span className={styles.outlineTitle}>404</span>
            </h1>
            <div className={styles.subtitleContainer}>
              <span className={styles.subtitle}>
                <strong>Whoops...</strong>
                <br></br>
                this page is not available!
              </span>
              <ButtonLink to="/" className={styles.button}>
                Go to homepage
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page404;
