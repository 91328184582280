import React from 'react';
import { Link } from 'gatsby';
import Button, { ButtonProps } from './Button';
import { clsx } from '../../utils/utils';

import * as styles from './ButtonLink.module.scss';

export interface ButtonLinkProps extends ButtonProps {
  to: string;
  className?: string;
}

const ButtonLink = ({ to, className, ...restProps }: ButtonLinkProps): React.ReactElement => {
  return (
    <Link className={clsx(styles.link, className)} to={to} tabIndex={-1}>
      <Button {...restProps}></Button>
    </Link>
  );
};

export default ButtonLink;
